import { Link } from "react-router-dom";
import css from "./Home.css";

function Home() {
  return (
    <div className="main-home">
      <div className="title">
        <div className="blue">Safu Frog</div>
        <div className="orange">Token Factory</div>
        <div className="blue subtitle">Mint safe tokens on Degen Chain & Base for free!</div>
        {/* <div className="blue subtitle">Mint safe tokens on Degen Chain for just $5!</div> */}
      </div>
      <div className="nav-btn-group">
        <Link className="nav-btn orange blue-background" to="/token-factory">
          Mint
        </Link>
        <Link className="nav-btn blue orange-background" to="/about">
          About
        </Link>
        <a className="nav-btn orange blue-background" href="https://safu-dex.web.app/" target="_blank">
          DEX
        </a>
      </div>
      {/* <div className="socials-btn-group">
        <a href="https://twitter.com/SafuFrog" target="_blank">
          <img className="socials-btn" src={twitterLogo}></img>
        </a>
        <a
          className="socials-btn"
          href="https://t.me/+IFQ330Lg9n43ZWZl"
          target="_blank"
        >
          <img className="socials-btn" src={telegramLogo}></img>
        </a>
      </div> */}
    </div>
  );
}

export default Home;
