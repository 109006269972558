import { useNavigate, useSearchParams } from "react-router-dom";
import "./TokenLaunchSuccess.css";

function TokenLaunchSuccess() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  return (
    <div className="main">
      <div className="title cursor" onClick={() => navigate("/")}>
        <div className="blue">Safu Frog</div>
        <div className="orange">Success!</div>
      </div>
      <div className="body">
        <p>You just:</p>
        <p>
          Minted {searchParams.get("tokenSupply")}{" "}
          {searchParams.get("tokenName")} ({searchParams.get("tokenTicker")}).
        </p>
        <p>
          Added {searchParams.get("nativeTokenLiquidity")} ${Number(searchParams.get("chainId")) === 666666666 ? "DEGEN" : Number(searchParams.get("chainId")) === 8453 ? "ETH" : "ETH"} as liquidity.
        </p>
        {searchParams.get("isDonation") === "true" ? (
          <></>
        ) : (
          <p>
            Sent {(Number(searchParams.get("tokenSupply")) * 10) / 100} to
            minter wallet address {searchParams.get("minterAddress")},
          </p>
        )}
        
        <p>
          Sent {Number(searchParams.get("tokenSupply"))} (
            {searchParams.get("tokenName")} {searchParams.get("tokenTicker")}) to DEX as LP.
        </p>
        <p>
          Burned 100% of LP.
        </p>
        <p>
          Sent {(Number(searchParams.get("tokenSupply")) * 1) / 100} {" "}
          {searchParams.get("tokenName")} ({searchParams.get("tokenTicker")}) to Safu
          Frog. Thank you for supporting Safu Frog!
        </p>
        {searchParams.get("isDonation") === "true" ? (
          <></>
        ) : (
          <p>
            Sent {(Number(searchParams.get("tokenSupply")) * 10) / 100} {" "}
            {searchParams.get("tokenName")} ({searchParams.get("tokenTicker")}) to
            minter wallet address {searchParams.get("minterAddress")},
          </p>
        )}
        <p>
          Your transaction hash was{" "}
          <a
            href={`https://${Number(searchParams.get('chainId')) === 666666666 ? "explorer.degen.tips" : Number(searchParams.get('chainId')) === 8453 ? 'basescan.org' : 'basescan.org'
              }/tx/${searchParams.get(
                "txHash"
              )}`}
            target="_blank"
          >
            {searchParams.get("txHash")}
          </a>{" "}
          and can be found at {Number(searchParams.get('chainId')) === 666666666 ? "explorer.degen.tips" : Number(searchParams.get('chainId')) === 8453 ? "basescan" : "basescan"}.
        </p>
      </div>
    </div>
  );
}

export default TokenLaunchSuccess;
