import "./NavBar.css";
import workerFrog from "../assets/workerFrog.jpg";
import { useSDK } from "@metamask/sdk-react";
import { useNavigate } from "react-router-dom";
import { connect, getChainLabel } from "../utils/blockchain";
import { useState } from "react";

function NavBar() {
  const { sdk, connected, connecting, provider, chainId } = useSDK();
  const navigate = useNavigate();

  const [account, setAccount] = useState();
  if (connected) {
    connect(sdk, setAccount);
  }

  return (
    <div className="main-navbar">
      <div className="far-left">
        <div className="logo-navbar blue" onClick={() => navigate("/")}>
          <img className="logo-navbar-img" src={workerFrog}></img>
          <p >Safu <span className="logo-navbar-title-frog">Frog</span></p>
        </div>
      </div>
      <div className="far-right">
        <div className="blue">
          Chain:{" "}
          {getChainLabel(Number(chainId))}
        </div>
        <button
          className="connect-btn-navbar orange"
          onClick={() => connect(sdk, setAccount)}
        >
          {connected && account
            ? account.slice(0, 6) + "..." + account.slice(-4)
            : "Connect Wallet"}
        </button>
      </div>
    </div>
  );
}

export default NavBar;
