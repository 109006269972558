import { useState } from "react";
import "./TokenFactoryV5.css";
import { useSDK } from "@metamask/sdk-react";
import { useNavigate } from "react-router-dom";
import SafuFrogTokenFactoryV5Abi from "../abis/SafuFrogTokenFactoryV5";
import { parseEther, Contract, BrowserProvider, formatEther } from "ethers";
import {
  SAFU_FROG_TOKEN_FACTORY_V5_ADDRESS,
  connect,
  getChainLabel,
  switchToBaseNetwork,
  switchToDegenNetwork,
} from "../utils/blockchain";

function TokenFactoryV5() {
  const [account, setAccount] = useState();
  const [isDonation, setIsDonation] = useState(true);
  const [dex, setDex] = useState();
  const [selectedChain, setSelectedChain] = useState(666666666);
  const navigate = useNavigate();
  const { sdk, connected, connecting, provider, chainId } = useSDK();

  connect(sdk, setAccount);

  function dexOnChange(event) {
    setDex(event.target.value);
  }

  function selectedChainOnChange(event) {
    setSelectedChain(Number(event.target.value));
  }

  async function mintToken(event) {
    console.log('hi')
    event.preventDefault();
    const formVals = event.target;

    const _provider = new BrowserProvider(window.ethereum);
    const signer = await _provider.getSigner();

    const SafuFrogTokenFactory = new Contract(
      SAFU_FROG_TOKEN_FACTORY_V5_ADDRESS[Number(selectedChain)],
      SafuFrogTokenFactoryV5Abi,
      signer
    );
    console.log('hi1')
    const tx = await SafuFrogTokenFactory.launchToken(
      dex,
      formVals.tokenName.value,
      formVals.tokenTicker.value,
      parseEther(formVals.tokenSupply.value),
      parseEther(formVals.tokenLiquidityNative.value),
      isDonation,
      {
        value: parseEther(formVals.tokenLiquidityNative.value),
      }
    );
    console.log('hi2')

    await tx.wait();
    console.log('hi3')

    navigate(
      `/token-launch-success?tokenName=${formVals.tokenName.value}&tokenTicker=${formVals.tokenTicker.value}&tokenSupply=${formVals.tokenSupply.value}&nativeTokenLiquidity=${formVals.tokenLiquidityNative.value}&minterAddress=${signer.address}&txHash=${tx.hash}&isDonation=${isDonation}&chain=${selectedChain}&dex=${dex}`
    );
  }

  return (
    <div className="main-factory">
      <div className="title cursor" onClick={() => navigate("/")}>
        <div className="blue">Safu Frog</div>
        <div className="orange">Token Factory</div>
      </div>

      <div className="token-form">
        <h1 className="blue">Design Your Token</h1>
        <form onSubmit={mintToken}>
          <label for="tokenName">Token Name *</label>
          <input
            className="input-token-factory"
            type="text"
            id="tokenName"
            name="tokenName"
            placeholder="What will your token's name be?"
            required
          ></input>
          <br />

          <label for="tokenTicker">
            Token Ticker (You do not need to type $) *
          </label>
          <input
            className="input-token-factory"
            type="text"
            id="tokenTicker"
            name="tokenTicker"
            placeholder="What will your token's ticker be?"
            required
          ></input>
          <br />

          <label for="tokenSupply">
            Token Supply (100% goes into LP, 100% of LP is burned) *
          </label>
          <input
            className="input-token-factory"
            type="number"
            id="tokenSupply"
            name="tokenSupply"
            placeholder="Total number of tokens minted"
            required
          ></input>
          <br />

          <label for="tokenLiquidityNative">
            Add Liquidity {selectedChain === 666666666 ? "DEGEN" : "ETH"} (100% goes into LP, 100% of LP is burned) *
          </label>
          <input
            className="input-token-factory"
            type="number"
            id="tokenLiquidityNative"
            name="tokenLiquidityNative"
            placeholder={"How many " + (selectedChain === 666666666 ? "DEGEN" : "ETH") + " of liquidity do you want to add?"}
            step="any"
            required
          ></input>
          <br />

          <label>Select Chain for LP</label>
          <label for="Degen">
            <input className="radio-btn" type="radio" id="Degen" name="Degen" value={666666666}
              checked={selectedChain === 666666666}
              onChange={selectedChainOnChange}
            ></input>
            Degen
          </label>
          <label for="Base">
            <input className="radio-btn" type="radio" id="Base" name="Base" value={8453}
              checked={selectedChain === 8453}
              onChange={selectedChainOnChange}
            ></input>
            Base
          </label>
          <br />

          <label>Select DEX ({getChainLabel(selectedChain)}) for LP</label>
          {
            selectedChain === 666666666 ?
              <>
                {/* <label for="SafuDex">
            <input className="radio-btn" type="radio" id="SafuDex" name="SafuDex" value="SAFU_SWAP"
              checked={dex === 'SAFU_SWAP'}
              onChange={dexOnChange}
            ></input>
            SafuDex
          </label> */}
                <label for="DegenSwap">
                  <input className="radio-btn" type="radio" id="DegenSwap" name="DegenSwap" value="DEGEN_SWAP"
                    checked={dex === 'DEGEN_SWAP'}
                    onChange={dexOnChange}
                  ></input>
                  DegenSwap
                </label>
                <label for="FrogSwap">
                  <input className="radio-btn" type="radio" id="FrogSwap" name="FrogSwap" value="FROG_SWAP"
                    checked={dex === 'FROG_SWAP'}
                    onChange={dexOnChange}
                  ></input>
                  FrogSwap
                </label>
              </> : selectedChain === 8453 ? <>
                <label for="SafuDex">
                  <input className="radio-btn" type="radio" id="SafuDex" name="SafuDex" value="SAFU_SWAP"
                    checked={dex === 'SAFU_SWAP'}
                    onChange={dexOnChange}
                  ></input>
                  SafuDex
                </label>
                <label for="UniswapDex">
                  <input className="radio-btn" type="radio" id="UniswapDex" name="UniswapDex" value="UNISWAP"
                    checked={dex === 'UNISWAP'}
                    onChange={dexOnChange}
                  ></input>
                  Uniswap
                </label>
              </> : <></>
          }
          <br />

          <label for="donation">Donate 1% of Supply to Safu Frog (Not Mandatory)</label>
          <input
            className="donation-checkbox"
            type="checkbox"
            id="donation"
            checked={isDonation}
            onChange={() => setIsDonation(!isDonation)}
          ></input>
          <br />

          {/* <label for="tokenLiquidityERC20">Add Liquidity Token *</label>
          <input type="number" id="tokenLiquidityERC20" name="tokenLiquidityERC20" placeholder="How many your token do you want to add?" required></input>
          <br />

          <label for="lockLiquidityPercent">Lock Liquidity % (Must be at least 75%) *</label>
          <input type="number" id="lockLiquidityPercent" name="lockLiquidityPercent" placeholder="What % of liquidity do you want to add?" required></input>
          <br />

          <label for="lockLiquidityPeriod">Lock Liquidity Period (Must be at least 1 month) *</label>
          <input type="number" id="lockLiquidityPercent" name="lockLiquidityPercent" placeholder="How many months do you want to lock your LP for?" required></input>
          <br /> */}

          {/* <label>Fee: 100 DEGEN</label> */}

          {connected && account ? (
            <>
              <br />
              <label>Connected Wallet: {account}</label>
              <label>
                Connected Chain: {getChainLabel(Number(chainId))}
              </label>
            </>
          ) : (
            <></>
          )}

          {connected && account ? (
            Number(chainId) === selectedChain ? (
              <input
                className="submit-btn"
                type="submit"
                value="Mint My Token!"
              ></input>
            ) : (
              <button
                type="button"
                className="submit-btn connect-metamask"
                onClick={() => {
                  if (selectedChain === 666666666) {
                    switchToDegenNetwork(provider);
                  } else if (selectedChain === 8453) {
                    switchToBaseNetwork(provider);
                  }
                }}
              >
                Switch to {getChainLabel(selectedChain)} Network
              </button>
            )
          ) : (
            <button
              type="button"
              className="submit-btn connect-metamask"
              onClick={connect}
            >
              Connect Metamask
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default TokenFactoryV5;
