import { Link, useNavigate } from "react-router-dom";
import "./About.css";

function About() {
  const navigate = useNavigate();

  return (
    <div className="main-about">
      <div className="title cursor" onClick={() => navigate("/")}>
        <div className="blue">Safu Frog</div>
        <div className="orange">About Us</div>
      </div>
      <div className="body">
        <p>
          Safu Frog is a free to use point-and-click token factory which only mints 100% fair-launch, unruggable tokens, with anti-sniper protections. Tokens created via our factory have 100% of tokens placed into an LP, and have 100% of that LP position burned.
        </p>
        <p>
          SafuDex is an associated DEX we built which allows people who create tokens via our platform to earn LP fees even though the LP position is burned. When you launch a token via our token factory and host its liquidity on SafuDex, 50% of LP fees will be sent to you.
        </p>
        <p>
          You can also use our factory to list a token on other DEXs like Uniswap or Frogswap, however the LP will still be burnt and you will not receive LP fees.
        </p>
        <p>
          To read more about how our anti-sniper protections work, and some case studies on how much a token might earn on LP fees, you can read the following two articles on our medium: <a href="https://medium.com/@safufrog/safufrogs-anti-sniper-factory-e8fbb02f9989" target="_blank">Anti-Sniper Article</a>, <a href="https://medium.com/@safufrog/the-safudex-concept-fd13fcbcee6f" target="_blank">LP Fees Article</a>.
        </p>
        <div className="nav-btn-group">
          <Link className="nav-btn blue orange-background" to="/">
            Home
          </Link>
          <Link className="nav-btn orange blue-background" to="/token-factory">
            Token Factory
          </Link>
        </div>
      </div>
    </div>
  );
}

export default About;
