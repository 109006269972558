export const SAFU_FROG_TOKEN_FACTORY_ADDRESS =
  "0x59D27C377d716c4e1Ed32E41E36DED4ec04f5D16";

export const SAFU_FROG_TOKEN_FACTORY_V2_ADDRESS =
  "0x66e5b3d89E54845A4d739f5b509B2AC0288c9d2F";

export const SAFU_FROG_TOKEN_FACTORY_V4_ADDRESS =
  "0x4bd93C7C6B04c79081D16e8a9CB2BE7117BB38f6";

export const SAFU_FROG_TOKEN_FACTORY_V5_ADDRESS = {
  666666666: '0xbd97deF7C7C9bF28348e8561fFF7152f4eAD1b9a',
  8453: '0x3C637Cf918B6744CE6359F03b2719240fa0C355b',
}

export const ROUTERS = {
  666666666: {
    safuswap: "0xc6353FA2703F4b46d360730E7fC632F3aD19a3bc",
    frogswap: "0xA7b7e7fdB1c64D0e716021e80cF5A3b4955CddE6",
    degenswap: "0x5A8e4e0dD630395B5AFB8D3ac5b3eF269f0c8356"
  },
  8453: {
    safuswap: "0x37b5E64d062857d2EBe03bb02BF0400DBC318200",
    uniswap: '0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24'
  }
}

export const FACTORIES = {
  666666666: {
    safuswap: '0x6097c6b72f88eB41c844799e142fd321e30e954d',
    frogswap: '0xA994635243b55468B9C421559516BdE229E0930B',
    degenswap: '0xA5E57CaB76caa09F66280F9Eb1529ed1059E87ba'
  }, 8453: {
    safuswap: '0xcFff30F4e7160bE2d7A45eE366e5C283AccC907F',
    uniswap: '0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6'
  }

}

export const SAFTU = {
  666666666: '0x3D3CAA30f409253B785AdC1A31f56C43A013847b',
  8453: '0x85400dada664ffbf1497d50d254d9e320104ee35',
}

export const connect = async (sdk, setAccount) => {
  try {
    const accounts = await sdk?.connect();
    setAccount(accounts?.[0]);
  } catch (err) {
    console.warn("failed to connect..", err);
  }
};

export const switchToDegenNetwork = async (provider) => {
  try {
    await provider // Or window.ethereum if you don't support EIP-6963.
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x27bc86aa" }],
      });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await provider // Or window.ethereum if you don't support EIP-6963.
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x27bc86aa",
                chainName: "Degen Chain",
                rpcUrls: ["https://rpc.degen.tips"],
                nativeCurrency: {
                  symbol: "DEGEN",
                  name: "Degen",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://explorer.degen.tips/"],
                iconUrls: [
                  "https://explorer.degen.tips/assets/network_icon.svg",
                ],
              },
            ],
          });
      } catch (addError) {
        // Handle "add" error.
      }
    }
    // Handle other "switch" errors.
  }
};

export const switchToBaseNetwork = async (provider) => {
  try {
    await provider // Or window.ethereum if you don't support EIP-6963.
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x2105" }],
      });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await provider // Or window.ethereum if you don't support EIP-6963.
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x2105",
                chainName: "Base",
                rpcUrls: ["wss://base-rpc.publicnode.com"],
                nativeCurrency: {
                  symbol: "ETH",
                  name: "Ether",
                  decimals: 18,
                },
                blockExplorerUrls: ["https://basescan.org/"],
                iconUrls: [
                  "https://avatars.githubusercontent.com/u/108554348?v=4",
                ],
              },
            ],
          });
      } catch (addError) {
        // Handle "add" error.
      }
    }
    // Handle other "switch" errors.
  }
};

export const getChainLabel = (chainId) => {
  if (chainId === 666666666) {
    return "Degen"
  } else if (chainId === 8453) {
    return "Base"
  } else {
    return "Unsupported"
  }
}