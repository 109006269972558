import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import TokenLaunchSuccess from './pages/TokenLaunchSuccess';
import TokenFactoryV1 from './pages/TokenFactoryV1';
import TokenFactoryV2 from './pages/TokenFactoryV2';
import TokenFactoryV4 from './pages/TokenFactoryV4';
import TokenFactoryV5 from './pages/TokenFactoryV5';
import NavBar from './components/NavBar';

function App() {
  return (
    <>
      <NavBar></NavBar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/token-factory" element={<TokenFactoryV5 />} />
        <Route path="/token-factory-v1" element={<TokenFactoryV1 />} />
        <Route path="/token-factory-v2" element={<TokenFactoryV2 />} />
        <Route path="/token-factory-anti-sniper" element={<TokenFactoryV4 />} />
        <Route path="/token-launch-success" element={<TokenLaunchSuccess />} />
      </Routes>
    </>

  );
}

export default App;
