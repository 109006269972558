import { useState } from "react";
import "./TokenFactoryV4.css";
import { useSDK } from "@metamask/sdk-react";
import { useNavigate } from "react-router-dom";
import SafuFrogTokenFactoryV4Abi from "../abis/SafuFrogTokenFactoryV4";
import { parseEther, Contract, BrowserProvider, formatEther } from "ethers";
import {
  SAFU_FROG_TOKEN_FACTORY_V4_ADDRESS,
  connect,
  switchToDegenNetwork,
} from "../utils/blockchain";

function TokenFactoryV4() {
  const [account, setAccount] = useState();
  const [isDonation, setIsDonation] = useState(true);
  const navigate = useNavigate();
  const { sdk, connected, connecting, provider, chainId } = useSDK();

  connect(sdk, setAccount);

  async function mintToken(event) {
    event.preventDefault();
    const formVals = event.target;

    const _provider = new BrowserProvider(window.ethereum);
    const signer = await _provider.getSigner();

    const SafuFrogTokenFactory = new Contract(
      SAFU_FROG_TOKEN_FACTORY_V4_ADDRESS,
      SafuFrogTokenFactoryV4Abi,
      signer
    );

    const tx = await SafuFrogTokenFactory.launchToken(
      formVals.tokenName.value,
      formVals.tokenTicker.value,
      parseEther(formVals.tokenSupply.value),
      parseEther(formVals.tokenLiquidityNative.value),
      isDonation,
      {
        value: parseEther(formVals.tokenLiquidityNative.value),
      }
    );

    await tx.wait();

    navigate(
      `/token-launch-success?tokenName=${formVals.tokenName.value}&tokenTicker=${formVals.tokenTicker.value}&tokenSupply=${formVals.tokenSupply.value}&nativeTokenLiquidity=${formVals.tokenLiquidityNative.value}&minterAddress=${signer.address}&txHash=${tx.hash}&isDonation=${isDonation}`
    );
  }

  return (
    <div className="main-factory">
      <div className="title cursor" onClick={() => navigate("/")}>
        <div className="blue">Safu Frog</div>
        <div className="orange">Anti Sniper Factory [BETA]</div>
      </div>

      <div className="token-form">
        <h1 className="blue">Design Your Token</h1>
        <form onSubmit={mintToken}>
          <label for="tokenName">Token Name *</label>
          <input
            className="input-token-factory"
            type="text"
            id="tokenName"
            name="tokenName"
            placeholder="What will your token's name be?"
            required
          ></input>
          <br />

          <label for="tokenTicker">
            Token Ticker (You do not need to type $) *
          </label>
          <input
            className="input-token-factory"
            type="text"
            id="tokenTicker"
            name="tokenTicker"
            placeholder="What will your token's ticker be?"
            required
          ></input>
          <br />

          <label for="tokenSupply">
            Token Supply (90% goes into LP and 10% to minter) *
          </label>
          <input
            className="input-token-factory"
            type="number"
            id="tokenSupply"
            name="tokenSupply"
            placeholder="Total number of tokens minted"
            required
          ></input>
          <br />

          <label for="tokenLiquidityNative">
            Add Liquidity DEGEN (All LP tokens will be burned) *
          </label>
          <input
            className="input-token-factory"
            type="number"
            id="tokenLiquidityNative"
            name="tokenLiquidityNative"
            placeholder="How many DEGEN of liquidity do you want to add?"
            required
          ></input>
          <br />

          <label for="donation">Donate 1% of Supply to Safu Frog (Not Mandatory)</label>
          <input
            className="donation-checkbox"
            type="checkbox"
            id="donation"
            checked={isDonation}
            onChange={() => setIsDonation(!isDonation)}
          ></input>
          <br />

          {/* <label for="tokenLiquidityERC20">Add Liquidity Token *</label>
          <input type="number" id="tokenLiquidityERC20" name="tokenLiquidityERC20" placeholder="How many your token do you want to add?" required></input>
          <br />

          <label for="lockLiquidityPercent">Lock Liquidity % (Must be at least 75%) *</label>
          <input type="number" id="lockLiquidityPercent" name="lockLiquidityPercent" placeholder="What % of liquidity do you want to add?" required></input>
          <br />

          <label for="lockLiquidityPeriod">Lock Liquidity Period (Must be at least 1 month) *</label>
          <input type="number" id="lockLiquidityPercent" name="lockLiquidityPercent" placeholder="How many months do you want to lock your LP for?" required></input>
          <br /> */}

          {/* <label>Fee: 100 DEGEN</label> */}

          {connected && account ? (
            <>
              <br />
              <label>Connected Wallet: {account}</label>
              <label>
                On DEGEN Network:{" "}
                {Number(chainId) === 666666666 ? "true" : "false"}
              </label>
            </>
          ) : (
            <></>
          )}

          {connected && account ? (
            Number(chainId) === 666666666 ? (
              <input
                className="submit-btn"
                type="submit"
                value="Mint My Token!"
              ></input>
            ) : (
              <button
                type="button"
                className="submit-btn connect-metamask"
                onClick={() => {
                  switchToDegenNetwork(provider);
                }}
              >
                Switch to Degen Network
              </button>
            )
          ) : (
            <button
              type="button"
              className="submit-btn connect-metamask"
              onClick={connect}
            >
              Connect Metamask
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default TokenFactoryV4;
